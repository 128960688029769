import { Injectable } from '@angular/core';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { ICustomerMedicalProfile, TOrderPrescription, TOrderPrescriptionScriptItem } from '@chemist2u/types-client/C2U/Interfaces';
import { LatLngLiteral } from '@googlemaps/google-maps-services-js';

export type TCombinedPrescriptionProfilesParam = TOrderPrescription[] | Omit<TOrderPrescription, "amount">[];
export type TCombinedPrescriptionProfilesResult = {
  profile: Parse.Object.ToJSON<ICustomerMedicalProfile> & Parse.JSONBaseAttributes,
  items: TOrderPrescriptionScriptItem[]
}[];

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  combinePrescriptionProfiles(prescriptions: TCombinedPrescriptionProfilesParam): TCombinedPrescriptionProfilesResult {
    if (prescriptions.length === 0) {
      return [];
    }

    const profileMap = new Map();

    for (const prescription of prescriptions) {
      const profile = prescription.prescriptionProfile;
      if (profile && !profileMap.has(profile.objectId)) {
        profileMap.set(profile.objectId, {
          profile,
          items: []
        });
      }
    }

    for (const prescription of prescriptions) {
      const items = prescription.items;
      const profileId = prescription.prescriptionProfile?.objectId;
      if (profileId) {
        profileMap.get(profileId).items.push(...items);
      }
    }

    for (const key of profileMap.keys()) {
      const profile = profileMap.get(key)!;
      if (profile.items.length == 0) {
        profileMap.delete(key);
      }
    }

    return Array.from(profileMap.values());
  }

  public calculateDistanceHaversine(
    point1: LatLngLiteral,
    point2: LatLngLiteral,
    unit: 'km' | 'miles' | 'meters' = 'km'
  ): number {
    const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

    const R = {
      km: 6371,          // Earth's radius in kilometers
      miles: 3958.8,     // Earth's radius in miles
      meters: 6371000,   // Earth's radius in meters
    };

    const lat1 = toRadians(point1.lat);
    const lat2 = toRadians(point2.lat);
    const deltaLat = toRadians(point2.lat - point1.lat);
    const deltaLon = toRadians(point2.lng - point1.lng);

    const a =
      Math.sin(deltaLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;

    const c = 2 * Math.asin(Math.sqrt(a));

    const distance = R[unit] * c;

    return distance;
  }
}