import { Component, computed, inject, input, output } from '@angular/core';
import { RadioComponent } from '../../primitives/radio/radio.component';
import { ImageComponent } from '../../primitives/image/image.component';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { IPharmacy, TAllocatedShift, TOrderFulfillmentMethod, TOrderFulfillmentMethodClickAndCollect, TOrderFulfillmentMethodOnDemand, TOrderFulfillmentMethodSameDay, TOrderFulfillmentMethodStandard, TSessionAddress } from '@chemist2u/types-client/C2U/Interfaces';
import { addMinutes } from 'date-fns';
import { SimpleButtonComponent } from "../../primitives/simple-button/simple-button.component";
import { ModalController, IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { locationOutline, storefront } from 'ionicons/icons';
import { AtlPage } from 'src/app/pages/atl/atl.page';
import { ReschedulePage } from 'src/app/pages/reschedule/reschedule.page';
import { TimeService } from 'src/app/services/time.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { StateService } from 'src/app/services/state.service';
import { UtilService } from 'src/app/services/util.service';
import { LatLngLiteral } from '@googlemaps/google-maps-services-js';

@Component({
  selector: 'app-delivery-method-option',
  templateUrl: './delivery-method-option.component.html',
  styleUrls: ['./delivery-method-option.component.scss'],
  standalone: true,
  imports: [
    RadioComponent, 
    ImageComponent, 
    CurrencyPipe, 
    DecimalPipe,
    SimpleButtonComponent, 
    SimpleButtonComponent, 
    IonIcon
  ]
})
export class DeliveryMethodOptionComponent {
  private $time = inject(TimeService);
  private $state = inject(StateService);
  private $util = inject(UtilService);
  private modalController = inject(ModalController);
  public session = toSignal(this.$state.bSession);
  public cart = toSignal(this.$state.bCart);
  public fullFillmentMethod = input<TOrderFulfillmentMethod>();
  public methodSelected = input<boolean>(false);
  public isAvailable = input<boolean>(true);
  public isSelectable = input<boolean>(true);
  public isDisabled = input<boolean>(false);
  public pharmacyAddress = input<Partial<IPharmacy> | undefined>();
  public customerAddress = input<TSessionAddress | undefined>();
  public isClickAndCollectUnavailable = input<boolean>(false);
  public canSetATL = input<boolean>(false);
  public canReschedule = input<boolean>(false);
  public selectEvent = output<TOrderFulfillmentMethod>();
  public atlEvent = output<{atl: boolean, instructions: string | undefined}>();
  public updateShiftForOnDemandEvent = output<TOrderFulfillmentMethod>();
  public location = input<"cart" | "default">("default");

  constructor() {
    addIcons({ storefront, locationOutline });
  }


  public distanceForClickAndCollect = computed(() => {
    if(this.fullFillmentMethod()?.selectedMethod.method === "clickAndCollect") {
      if(this.pharmacyAddress() && this.customerAddress()) {
        const pharmacyLocation = this.pharmacyAddress()!.location;
        if(!pharmacyLocation) return undefined;
        const pharmacyGeoPoint: LatLngLiteral = { lat: pharmacyLocation!.latitude, lng: pharmacyLocation!.longitude };
        const customerGeoPoint: LatLngLiteral = this.customerAddress()!.address?.geometry?.location;
        if(!customerGeoPoint) return undefined;
        return this.$util.calculateDistanceHaversine(pharmacyGeoPoint, customerGeoPoint);
      }
      return undefined;
    }
    return undefined;
  });

  public pAddress = computed(() => {
    if(this.fullFillmentMethod()?.selectedMethod.method !== "clickAndCollect") {
      return undefined;
    }
    if(this.pharmacyAddress()) {
      return this.pharmacyAddress()?.googleAddress?.formatted_address;
    }
    return undefined;
  });

  public atlBtnLabel = computed(() => {
    if(this.location() === "cart") {
      return this.cart()?.address?.atl ? "Authority to leave" : "Someone will be home";
    }
    return this.session()?.address?.atl ? "Authority to leave" : "Someone will be home";
  });

  selectMethod() {
    if (this.isSelectable()) {
      this.selectEvent.emit(this.fullFillmentMethod()!);
    }
  }

  public hasControls = computed(() => {
    return this.canSetATL() || this.canReschedule();
  });

  public isClickAndCollect = computed(() => {
    return this.fullFillmentMethod()?.selectedMethod.method === "clickAndCollect";
  });

  public scheduleName = computed(() => {
    const method = this.fullFillmentMethod()?.selectedMethod.method;
    switch (method) {
      case "OnDemand":
        const allocatedShift = (this.fullFillmentMethod() as TOrderFulfillmentMethodOnDemand)!.allocatedShift;
        return this.$time.formatShiftLabel(allocatedShift!);
      case "Standard":
      case "SameDay":
        const expectedDeliveryDate = (this.fullFillmentMethod() as TOrderFulfillmentMethodStandard | TOrderFulfillmentMethodSameDay)!.expectedDeliveryDate!;
        const hourDiff = method == "SameDay" ? 3 : 4;
        const psuedoShift = {
          pickup: expectedDeliveryDate,
          dropoff: new Date(expectedDeliveryDate.getTime() + hourDiff * 60 * 60 * 1000),
          day: this.$time.formatDay(expectedDeliveryDate),
          date: expectedDeliveryDate,
        } as unknown as TAllocatedShift;
        return this.$time.formatShiftLabel(psuedoShift);
      case "clickAndCollect":
        return "Click & Collect";
      case "Postal":
        return "Postal";
      case "PostalTemperatureControlled":
        return "Postal Temperature Controlled";
      default:
        return "Unknown";
    }
  });

  public description = computed(() => {
    const method = this.fullFillmentMethod()?.selectedMethod.method;
    switch (method) {
      case "OnDemand":
        const allocatedShift = (this.fullFillmentMethod() as TOrderFulfillmentMethodOnDemand)!.allocatedShift;
        return this.formatCutoffSublabel(allocatedShift!);
      case "Standard":
      case "SameDay":
        const typedMethod = (this.fullFillmentMethod() as TOrderFulfillmentMethodStandard | TOrderFulfillmentMethodSameDay)!;
        const expectedDeliveryDate = typedMethod.expectedDeliveryDate!;
        const cutoffMinutes = 'standardDeliveryCutoffMinutes' in typedMethod.selectedMethod
          ? typedMethod.selectedMethod.standardDeliveryCutoffMinutes
          : typedMethod.selectedMethod.sameDayCutoffMinutes;
        return this.formatPaymentDescription(cutoffMinutes, expectedDeliveryDate);
      case "clickAndCollect":
        return this.formatPickupAvailability((this.fullFillmentMethod() as TOrderFulfillmentMethodClickAndCollect)!.expectedPickupDate!, (this.fullFillmentMethod() as TOrderFulfillmentMethodClickAndCollect)!.selectedMethod.clickAndCollectBufferMinutes!);
      case "SameDay":
        return "description";
      case "Postal":
        return "Express post 1-2 business days if paid by midday.";
      case "PostalTemperatureControlled":
        return "Express post 1-2 business days if paid by midday. Temperature controlled packaging.";
      default:
        return "Description";
    }
  });

  public deliveryLabelName = computed(() => {
    const method = this.fullFillmentMethod()?.selectedMethod.method;
    switch (method) {
      case "OnDemand":
        return "Premium";
      case "Standard":
        return "Standard";
      case "clickAndCollect":
        return "Click & Collect";
      case "SameDay":
        return "Standard";
      case "Postal":
        return "Postal";
      default:
        return "Chemist2U";
    }
  });

  public free = computed(() => {
    return this.fullFillmentMethod()?.selectedMethod.isFree || this.fullFillmentMethod()?.selectedMethod.deliveryFee === 0;
  });

  public price = computed(() => {
    return this.fullFillmentMethod()?.selectedMethod.deliveryFee;
  });

  formatPickupAvailability(expectedPickupDate: Date, clickAndCollectBufferMinutes: number): string {
    // Calculate the next available pickup time by adding the buffer to the expectedPickupDate
    const nextAvailableTime = addMinutes(expectedPickupDate, 0);

    // Determine the day label ('today' or 'tomorrow') based on the time difference
    const today = new Date();
    const isToday = nextAvailableTime.getDate() === today.getDate() &&
      nextAvailableTime.getMonth() === today.getMonth() &&
      nextAvailableTime.getFullYear() === today.getFullYear();
    const dayLabel = isToday ? 'today' : 'tomorrow';

    // Format the next available pickup time
    const timeFormatOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = nextAvailableTime.toLocaleTimeString('en-US', timeFormatOptions).toLowerCase();

    // Determine the buffer label (in hours or minutes)
    let bufferLabel;
    if (clickAndCollectBufferMinutes % 60 === 0) {
      const hours = clickAndCollectBufferMinutes / 60;
      bufferLabel = hours === 1 ? '1 hour' : `${hours} hours`;
    } else {
      bufferLabel = `${clickAndCollectBufferMinutes} minutes`;
    }

    return `Available ${bufferLabel} after payment, next available pickup ${dayLabel} ${formattedTime}.`;
  }

  formatPaymentDescription(standardDeliveryCutoffMinutes: number, day: Date): string {
    const hours = Math.floor(standardDeliveryCutoffMinutes / 60);
    const minutes = standardDeliveryCutoffMinutes % 60;
    const amPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours > 12 ? hours - 12 : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const dayLabel = this.$time.formatDay(day);
    
    const cutoffTime = `${formattedHours}:${formattedMinutes} ${amPm} ${dayLabel}`;

    return `If payment is received before ${cutoffTime}. Delivery fee waived if order is $75+ or 3+ scripts.`;
  }

  formatCutoffSublabel(shift: TAllocatedShift): string {
    const formatOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const locale = 'en-AU'; // Adjust locale if necessary

    const cutoffTime = shift.cutoff.toLocaleTimeString(locale, formatOptions).toLowerCase();
    const dayLabel = this.$time.formatDay(shift.date);

    return `If payment is received before ${cutoffTime} ${dayLabel}.`;
  }

  async reschedule(event: MouseEvent) {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: ReschedulePage,
      canDismiss: true,
      presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
    })
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data as TAllocatedShift) {
      const updateFullfillmentMethod = { ...this.fullFillmentMethod()!, allocatedShift: data };
      this.updateShiftForOnDemandEvent.emit(updateFullfillmentMethod);
    }
  }

  async atl(event: MouseEvent) {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: AtlPage,
      canDismiss: true,
      presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
    })
    await modal.present();
    const { data } = await modal.onWillDismiss<{atl: boolean, instructions: string | undefined}>();
    if(data) {
      this.atlEvent.emit(data);
    }
  }

}