import { Component, computed, inject, model, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonFooter } from '@ionic/angular/standalone';
import { ModalController, LoadingController, AlertController } from "@ionic/angular/standalone";
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { CheckoutStepperComponent } from 'src/app/components/elements/checkout-stepper/checkout-stepper.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { CheckoutSectionComponent } from 'src/app/components/elements/checkout-section/checkout-section.component';
import { PrescriptionCardComponent } from 'src/app/components/elements/prescription-card/prescription-card.component';
import { ProductRowComponent } from 'src/app/components/elements/product-row/product-row.component';
import { CouponRowComponent } from 'src/app/components/elements/coupon-row/coupon-row.component';
import { CartTotalsComponent } from 'src/app/components/elements/cart-totals/cart-totals.component';
import { DeliveryCartComponent } from 'src/app/components/elements/delivery-cart/delivery-cart.component';
import { PrescriptionRowComponent } from "../../components/elements/prescription-row/prescription-row.component";
import { DeliveryMethodComponent } from 'src/app/components/elements/delivery-method/delivery-method.component';
import { DeliveryMethodOptionComponent } from 'src/app/components/elements/delivery-method-option/delivery-method-option.component';
import { CheckboxComponent } from 'src/app/components/primitives/checkbox/checkbox.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { StateService } from 'src/app/services/state.service';
import { ErrorService } from 'src/app/services/error.service';
import { ICustomerMedicalProfile, IOrder, IPromotionCustomer, TCartItem, TOrderFulfillmentMethod, TOrderFulfillmentMethodOnDemand, TOrderFulfillmentMethodPostal, TOrderItem, TOrderPrescription, TOrderPrescriptionScriptItem } from '@chemist2u/types-client/C2U/Interfaces';
import { ItemCartEditPage } from '../item-cart-edit/item-cart-edit.page';
import { AddressDetailsComponent } from 'src/app/components/elements/address-details/address-details.component';
import { UiService } from 'src/app/services/ui.service';
import { PrescriptionsEmptyComponent } from 'src/app/components/elements/prescription-card/prescriptions-empty/prescriptions-empty.component';
import { OtcItemsEmptyComponent } from 'src/app/components/elements/product-row/otc-items-empty/otc-items-empty.component';
import { PaymentWrapperComponent } from 'src/app/components/elements/payment-wrapper/payment-wrapper.component';
import { CardEway, Cart } from '@chemist2u/types-client/C2U/ParseObjects';
import { UtilService } from 'src/app/services/util.service';
import { CouponListPage } from '../coupon-list/coupon-list.page';
import { PromotionRowComponent } from 'src/app/components/elements/promotion-row/promotion-row.component';
import { PushService } from 'src/app/services/push.service';
import { Browser } from '@capacitor/browser';
import { TSerializedParseObject } from '@chemist2u/types-client/C2U/default';
import C2U from '@chemist2u/types-client';
import { Subscription } from 'rxjs';
import { CountdownComponent } from 'src/app/components/elements/countdown/countdown.component';
import { CartService } from 'src/app/services/cart.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { StepsContainerComponent } from 'src/app/components/scaffolds/steps-container/steps-container.component';
import { BannerComponent } from 'src/app/components/elements/banner/banner.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
  standalone: true,
  imports: [
    IonFooter,
    IonContent,
    IonHeader, 
    CommonModule,
    FormsModule,
    HeaderComponent,
    SimpleButtonComponent,
    TitleComponent,
    CheckoutStepperComponent,
    FooterComponent,
    ButtonComponent,
    CheckoutSectionComponent,
    PrescriptionCardComponent,
    ProductRowComponent,
    CouponRowComponent,
    CartTotalsComponent,
    DeliveryCartComponent,
    PrescriptionRowComponent,
    DeliveryMethodComponent,
    DeliveryMethodOptionComponent,
    CheckboxComponent,
    AddressDetailsComponent,
    PrescriptionsEmptyComponent,
    OtcItemsEmptyComponent,
    PaymentWrapperComponent,
    PromotionRowComponent,
    CountdownComponent,
    StepsContainerComponent,
    BannerComponent
  ],
})
export class CartPage implements OnInit, OnDestroy {
  // =============================================================================
  // STATIC DATA DEFINITIONS -- needs to be moved to definitions file
  // =============================================================================
  //TODO - need to move this to definitions file
  private weightLossExceptionItems: C2U.Cloud.TWeightLossItems[] = ['ozempic', 'semaglutide', 'wegovy', 'rybelsus', 'mounjaro', 'tirzepatide', 'nicovape', 'veev', 'vape'];

  //Static data -- TODO - move to definitions file
  public postalMethod: TOrderFulfillmentMethodPostal = {
    selectedMethod: {
      method: 'Postal',
      isFree: false,
      deliveryFee: 9.95,
    },
  };

  public postalTemperatureControlledMethod: TOrderFulfillmentMethod = {
    selectedMethod: {
      method: 'PostalTemperatureControlled',
      isFree: false,
      deliveryFee: 20,
    },
  };

  // =============================================================================
  // SERVICE INJECTIONS
  // =============================================================================
  private $amplitude = inject(AmplitudeService);
  private $state = inject(StateService);
  private $cart = inject(CartService);
  private $push = inject(PushService);
  private $error = inject(ErrorService);
  private $util = inject(UtilService);
  private $ui = inject(UiService);
  private loadingController = inject(LoadingController);
  private alertController = inject(AlertController);
  private modalController = inject(ModalController);

  // =============================================================================
  // SUBSCRIPTIONS
  // =============================================================================
  private fulfillmentMethodSub?: Subscription; //pharmacy available fulfillment methods
  private cartSub?: Subscription; //cart subscription for updating cart signal



  // =============================================================================
  // SIGNALS & VARIABLES DECLARATION
  // =============================================================================
  public currentStep = signal<number>(0); //Current page 0 = cart, 1 = shipping, 2 = payment
  private stepLabels: ["cart", "shipping", "payment"] = ["cart", "shipping", "payment"];

  // SERVICE STATE SIGNALS
  public orderFulfillmentMethods = toSignal(this.$state.bAvailableFulfillmentMethods);
  public session = toSignal(this.$state.bSession);
  private cards = toSignal(this.$state.bCards);
  private pharmacy = toSignal(this.$state.bAllocatedPharmacy);

  // LOCAL SIGNALS
  public cart = signal<Cart | undefined>(undefined);
  public postalWeightLossTermsAccepted = signal<boolean>(false);
  public selectedFulfillmentMethod = signal<TOrderFulfillmentMethod | undefined>(undefined);

  // MODEL SIGNALS
  public agreeToTempControlTerms = model<boolean>(false);


  // =============================================================================
  // COMPUTED VALUES
  // =============================================================================
  // UI computed values
  public cartActive = computed(() => this.currentStep() === 0); //Cart page active
  public shippingActive = computed(() => this.currentStep() === 1); //Shipping page active
  public paymentActive = computed(() => this.currentStep() === 2); //Payment page active
  public currentStepLabel = computed(() => this.stepLabels[this.currentStep()] || "cart"); //Current step label used for amplitude tracking
  public labelForCheckoutButton = computed(() => this.cart()?.hasScriptsInReview ? "Submit order for review" : "Pay now"); //Label for checkout button

  public labelForCheckoutDescription = computed(() => {
    if (this.cart()?.hasScriptsInReview) {
      return "Your card won't be charged until we've confirmed pricing . You will recieve a notification to make payment.";
    } else {
      return "";
    }
  })

  public pharmacyAddress = computed(() => this.pharmacy()?.attributes);

  // If the selected fulfillment method is postal
  public isPostalFulfillmentMethod = computed(() => this.selectedFulfillmentMethod()?.selectedMethod.method === 'Postal');

  // Fulfillment method to use for order

  public fulfillmentMethodToUse = computed(() => {
    if (this.isPostalFulfillmentMethod() && this.doesCartHaveWeightLossScriptItems()) {
      if (this.isNicovapeOnly()) {
        return this.postalMethod;
      } else {
        return this.postalTemperatureControlledMethod;
      }
    } else if (this.isPostalFulfillmentMethod() && !this.doesCartHaveWeightLossScriptItems()) {
      return this.selectedFulfillmentMethod();
    } else if (this.isWeightLossOnly()) {
      if (this.isNicovapeOnly()) {
        return this.postalMethod;
      } else {
        return this.postalTemperatureControlledMethod;
      }
    } else {
      return this.selectedFulfillmentMethod();
    }
  });


  // Non-weightloss scripts present
  public doWeHaveNonWeightLossScripts = computed(() => this.nonWeightLossPrescriptions() && this.nonWeightLossPrescriptions()!.length > 0);

  // Is the cart weightloss only
  public isWeightLossOnly = computed(() => this.doesCartHaveWeightLossScriptItems() && !this.doWeHaveNonWeightLossScripts() && !this.doWeHaveOTCItems());

  // Is nicovape only 
  public isNicovapeOnly = computed(() => {
    const prescriptions = this.cart()?.prescriptions || [];
    return prescriptions.length === 1 && prescriptions[0].items.length === 1 && (prescriptions[0].items[0].productName.toLowerCase().includes('nicovape') || prescriptions[0].items[0].productName.toLowerCase().includes('veev') || prescriptions[0].items[0].productName.toLowerCase().includes('vape'));
  });


  // Returns true if weightloss scripts present && not postal fulfillment method
  public doWeSplitOrder = computed(() => this.doesCartHaveWeightLossScriptItems() && !this.isPostalFulfillmentMethod() && (this.doWeHaveOTCItems() || this.doWeHaveNonWeightLossScripts()));

  // Selected Coupon
  public selectedCoupon = computed<TSerializedParseObject<IPromotionCustomer> | undefined>(() => this.cart()?.promotion);

  // Cart Totals - Legacy (TODO - remove this)
  public cartTotals = computed(() => this.cart() ? this.cart()!.totals : undefined);

  // Cart Totals List - Array of totals for split orders
  public cartTotalsList = computed(() => this.cart() ? this.cart()!.totalsList : []);

  // OTC Items
  public otcItems = computed(() => this.cart() ? this.cart()!.items : []);

  // OTC Items present
  public doWeHaveOTCItems = computed(() => this.otcItems() && this.otcItems()!.length > 0);


  // Session Address
  public placeDetails = computed(() => this.session()?.address);
  public canProceedToPayment = computed(() => {
    console.error("[CartPage]", "canProceedToPayment", "agreeToTempControlTerms", this.agreeToTempControlTerms());
    console.error("[CartPage]", "canProceedToPayment", "isPostalFulfillmentMethod", this.isPostalFulfillmentMethod());
    console.error("[CartPage]", "canProceedToPayment", "doesCartHaveWeightLossScriptItems", this.doesCartHaveWeightLossScriptItems());
    console.error("[CartPage]", "canProceedToPayment", "isWeightLossOnly", this.isWeightLossOnly());
    if (!this.session()?.address) return false;
    if ((this.doesCartHaveWeightLossScriptItems()) || this.isWeightLossOnly()) {
      console.error("[CartPage]", "canProceedToPayment", this.agreeToTempControlTerms());
      return this.agreeToTempControlTerms();
    }
    return true;
  });

  // Preferred Fulfillment Method
  public preferredMethodOnSession = computed(() => this.session()?.prefFulfillmentMethod);

  // Selected Card - stores card selection modal value
  public selectedCard = signal<CardEway | undefined>(undefined);

  // Card to use - computed value for card to use based off selected card or first card in list
  public cardToUse = computed(() => {
    //If card is selected, return that
    if (this.selectedCard()) {
      return this.selectedCard();
    }

    //If we have no cards, return undefined
    if (!this.cards() || this.cards()!.length === 0) {
      return undefined;
    }

    //Sort cards by updatedAt and return the first one
    return this.cards()!.sort((a, b) => a.updatedAt ? -1 : 1)[0];
  });


  //Weight loss present -- looks at script items
  public doesCartHaveWeightLossScriptItems = computed(() => {
    const prescriptions = this.cart()?.prescriptions || [];
    return prescriptions?.some(prescription =>
      prescription.items.some(item =>
        this.weightLossExceptionItems.some(ozempicItem =>
          item.productName.toLowerCase().includes(ozempicItem.toLowerCase())
        )
      )
    );
  });



  //List of: non-weightloss scripts
  public nonWeightLossPrescriptions = computed(() => {
    const prescriptions = this.cart()?.prescriptions ?? [];
    return prescriptions.filter(prescription => !prescription.items.some(item =>
      this.weightLossExceptionItems.some(ozempicItem =>
        item.productName.toLowerCase().includes(ozempicItem.toLowerCase())
      )
    ));
  });

  //List of: weightloss scripts
  public weightLossPrescriptions = computed(() => {
    const prescriptions = this.cart()?.prescriptions ?? [];
    return prescriptions.filter(prescription => prescription.items.some(item =>
      this.weightLossExceptionItems.some(ozempicItem =>
        item.productName.toLowerCase().includes(ozempicItem.toLowerCase())
      )
    ));
  });

  //List of: profile combined NON-weightloss scripts
  public combinedNonWeightLossPrescriptionItems = computed(() => {
    return this.$util.combinePrescriptionProfiles(this.nonWeightLossPrescriptions());
  });

  //List of: profile combined weightloss scripts
  public combinedWeightLossPrescriptionItems = computed(() => {
    return this.$util.combinePrescriptionProfiles(this.weightLossPrescriptions());
  });

  //List of: profile combined scripts
  public combinedPrescriptionItems = computed(() => {
    const prescriptions = this.cart()?.prescriptions ?? [];
    return this.$util.combinePrescriptionProfiles(prescriptions);
  });

  //Show holiday message each year
  public showHolidayMessage = computed(() => {
    const now = new Date();
    
    // December is month index 11 (0-based), so 11 = December.
    const year = now.getFullYear();
    const startDate = new Date(year, 11, 11); // December 20th at 00:00 local time
    const endDate = new Date(year, 11, 30, 23, 59, 59, 999); // December 30th end of day

    // Show message if current date/time is between startDate and endDate inclusive
    return now >= startDate && now <= endDate;
  });



  // =============================================================================
  // LIFECYCLE METHODS
  // =============================================================================
  async ngOnInit(): Promise<void> {
    this.$amplitude.track('MODAL_SHOW_CART');
    this.initializeCart();
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }



  // =============================================================================
  // UTILITY METHODS
  // =============================================================================
  private initializeCart() {
    //Set the initial values of the signals
    this.cart.set(this.$state.bCart.getValue());
    this.selectedFulfillmentMethod.set(this.$state.bSelectedFulfillmentMethod.getValue());
  }

  private setupSubscriptions() {
    //Instead of using toSignal we have to use subscribe to get the value of the signal
    this.cartSub = this.$state.bCart.subscribe(async cart => {
      if (!cart || (cart.items?.length === 0 && cart.prescriptions?.length === 0)) {
        await this.modalController.dismiss(undefined, undefined, "cart-modal");
        this.$amplitude.track('MODAL_DISMISS', { modal: 'cart' });
      }
      this.cart.set(cart);
    });

    //Subscribe to the selected fulfillment method
    this.fulfillmentMethodSub = this.$state.bSelectedFulfillmentMethod.subscribe(method => {
      this.selectedFulfillmentMethod.set(method);
    });
  }

  private unsubscribeAll() {
    //Unsubscribe from the signals
    if (this.cartSub) this.cartSub.unsubscribe();
    if (this.fulfillmentMethodSub) this.fulfillmentMethodSub.unsubscribe();
  }





  // =============================================================================
  // EVENT HANDLERS
  // =============================================================================
  navigateToStep(targetStep: number) {
    this.currentStep.set(targetStep);
    this.$amplitude.track('CART_STEP_CHANGE', { step: this.stepLabels[targetStep] });
  }

  nextStep() {
    this.navigateToStep(this.currentStep() + 1);
  }

  async goToPayment() {
    if (this.canProceedToPayment()) {
      this.nextStep();
    } else {

      const loader = await this.loadingController.create();
      try {
        const alert = await this.alertController.create({
          header: 'Agree to terms',
          message: "Your order will be shipped following the relevant manufacturer's guidelines, which allow semaglutide to be stored for up to 42 days, and Mounjaro for up to 21 days, at room temperature (not exceeding 30°C). To ensure that room temperature is not exceeded, we ship the medication in an insulated box with a cold pack inside. That way you have the peace of mind of knowing your medication has arrived in perfect condition.",
          buttons: [
            {
              text: 'No',
              role: 'cancel',
            }, {
              text: 'Yes',
              handler: () => { }
            }
          ]
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        if (role === 'cancel') return;
        loader.present();
        this.agreeToTempControlTerms.set(true);
        this.nextStep();
      } catch (error) {
        this.$error.showToast({ message: "There was an error agreeing to terms of shipment." });
        console.error(error);
      } finally {
        loader.dismiss();
      }
    }
  }

  async closeCart() {
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss();
      this.$amplitude.track('MODAL_DISMISS_CART');
    }
  }



  // =============================================================================
  // UI METHODS
  // =============================================================================
  // Edit address
  editAddress() {
    this.$amplitude.track('CART_INITED_SET_ADDRESS');
    this.$ui.setAddress.next(this.session()?.address);
  }

  // Edit delivery notes
  editDeliveryNotes() {
    this.$ui.setDeliveryNotes.next(this.session()!.address!);
  }

  // Show help in browser modal
  async help() {
    await Browser.open({ url: 'https://www.chemist2u.com.au/app-help', presentationStyle: "popover" });
    this.$amplitude.track('OPEN_EXTERNAL_HELP');
  }

  //Return method for selected fulfillment method from component emitter
  async methodSelected(method: TOrderFulfillmentMethod, reschedule: boolean = false, label: string = "NOTSET") {
    console.warn("[CartPage]", "methodSelected", method, reschedule, label);
    const currentMethod = this.$state.bSelectedFulfillmentMethod.getValue();
    console.warn("[CartPage]", "currentMethod", currentMethod);
    if (currentMethod?.selectedMethod.method == method.selectedMethod.method && !reschedule) return;
    const loader = await this.loadingController.create();
    await loader.present();
    this.$state.bSelectedFulfillmentMethod.next(method);
    console.warn("[CartPage]", "methodSelected", method);
    await this.$push.cart(this.cart()!, {
      fulfillmentDetails: method,
    });
    const methodName = method.selectedMethod.method;
    this.$amplitude.track('CART_FULFILLMENT_METHOD_CHANGE', { methodName });
    loader.dismiss();
  }




  // =============================================================================
  // CART ITEM & SCRIPT MANIPULATION METHODS
  // =============================================================================
  // Remove OTC item from cart
  async removeItemFromCart(index: number) {
    const loader = await this.loadingController.create();
    await loader.present();
    try {
      if (!this.cart()) {
        throw new Error("Cart not found");
      }
      const items = this.cart()!.items;
      if (!items) {
        throw new Error("Items not found");
      }
      if (!items[index]) {
        throw new Error("Item not found");
      }
    } catch (error) {
      this.$error.showToast({ message: "There was an error removing the item" });
      await loader.dismiss();
      return;
    }
    const item = this.cart()!.items![index];
    const id = item.objectId;
    const itemName = item.name;
    try {
      const itemCount = this.cart()!.combinedItemCount;
      await this.$cart.removeOTCItem(id);
      this.$amplitude.track('CART_ITEM_REMOVE_SUCCESS', { id, name: itemName });
      if (itemCount == 1) {
        this.$amplitude.track('CART_EMPTIED');
      }
    } catch (error) {
      this.$amplitude.track('CART_ITEM_REMOVE_FAIL', { id, name: itemName });
      this.$error.showToast({ message: "There was an error removing the item" });
    } finally {
      loader.dismiss();
    }

  }

  // Remove Script from cart
  async removeScript(item: TOrderPrescriptionScriptItem, profile: Parse.Object.ToJSON<ICustomerMedicalProfile> & Parse.JSONBaseAttributes) {
    const loader = await this.loadingController.create();
    try {
      const alert = await this.alertController.create({
        header: 'Remove script',
        message: 'Are you sure?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
          }, {
            text: 'Yes',
            handler: () => { }
          }
        ]
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'cancel') return;
      loader.present();
      const itemCount = this.cart()!.combinedItemCount;
      const prescriptions = this.cart()!.prescriptions || [];
      let prescriptionIndex;
      prescriptions.forEach((prescription, index) => {
        const sameProfile = prescription.prescriptionProfile.objectId === profile.objectId;
        const hasItem = prescription.items.filter(script => script.productName === item.productName).length > 0;
        if (sameProfile && hasItem) {
          prescriptionIndex = index;
        }
      });
      if (prescriptionIndex == undefined) return;
      const profilePrescription = prescriptions[prescriptionIndex];
      const newItems = profilePrescription.items.filter(script => script.productName !== item.productName);
      if (newItems.length === 0) {
        prescriptions.splice(prescriptionIndex, 1);
        await this.$push.cart(this.cart()!, {
          ...this.cart()!.attributes,
          prescriptions,
        })
      } else {
        prescriptions[prescriptionIndex].items = newItems;
        await this.$push.cart(this.cart()!, {
          ...this.cart()!.attributes,
          prescriptions,
        });
      }
      this.$amplitude.track('CART_SCRIPT_REMOVE_SUCCESS');
      if (itemCount == 1) {
        this.$amplitude.track('CART_EMPTIED');
      }
    } catch (error) {
      this.$amplitude.track('CART_SCRIPT_REMOVE_FAIL');
      this.$error.showToast({ message: "There was an error removing the script item" });
      console.error(error);
    } finally {
      loader.dismiss();
    }
  }

  // Remove Promotion from cart
  public async clearCoupon() {
    try {
      await this.$cart.removePromotion();
      this.$amplitude.track('CART_PROMOTION_CLEAR_SUCCESS');
    } catch (error) {
      this.$amplitude.track('CART_PROMOTION_CLEAR_FAIL');
      throw error;
    }
  }

  // Select a promotion from Promotion  modal
  public async selectCoupon() {
    try {
      this.$amplitude.track('CART_PROMOTION_SELECT_OPEN');
      const modal = await this.modalController.create({
        component: CouponListPage,
        canDismiss: true,
        animated: true,
        showBackdrop: true,
        presentingElement: await this.modalController.getTop(),
        componentProps: {
          pageState: "modal",
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      this.$amplitude.track('CART_PROMOTION_SELECT_CLOSE');
      if (data) {
        this.cart()!.promotion = data.toJSON();
        await this.$push.cart(this.cart()!);
        this.$amplitude.track('CART_PROMOTION_SELECT_SUCCESS');
      }
    } catch (error) {
      this.$amplitude.track('CART_PROMOTION_SELECT_FAIL');
      console.error(error);
    } finally {
    }
  }

  // Select a card from the card modal
  public changeCard(payment: CardEway) {
    this.selectedCard.set(payment);
    this.$amplitude.track('CART_CARD_SELECTION_CHANGE');
  }

  // Add a new prescription an dismiss the cart
  async addPrescriptions() {
    this.$ui.addPrescriptionsFromCart.next();
    this.closeCart();
  }

  // Add a new OTC item and dismiss the cart
  async addOTCItems() {
    this.$ui.addOTCItemsFromCart.next();
    this.closeCart();
  }

  // Edit OTC cart item quantity or remove item
  async editCartItem(product: TCartItem) {
    const modal = await this.modalController.create({
      component: ItemCartEditPage,
      componentProps: {
        product,
      },
      canDismiss: true,
      presentingElement: await this.modalController.getTop(),
      backdropDismiss: true,

    });
    await modal.present();
  }


  // Set ATL
  async setAtl(atl: { atl: boolean, instructions: string | undefined }) {
    // alert(JSON.stringify(atl));

    const loader = await this.loadingController.create({
      message: 'Saving ATL...',
    });
    try {
      await loader.present();
      await this.$push.cart(this.cart()!, {
        ...this.cart()!.attributes,
        address: {
          ...this.session()!.address!,
          atl: atl.atl,
          deliveryNote: atl.instructions?.trim() || ""
        }
      });
      await loader.dismiss();
      this.$amplitude.track('ATL_SAVE_SUCCESS');
    } catch (error) {
      await loader.dismiss();
      console.error("Error saving ATL", error);
      this.$amplitude.track('ATL_SAVE_FAIL');
    } finally {
      await loader.dismiss();
    }

  }



  // =============================================================================
  // CREATE ORDER
  // =============================================================================
  async createOrder() {
    const loader = await this.loadingController.create();
    await loader.present();

    try {
      let weightLossPrescriptions;
      let nonWeightLossPrescriptions;
      try {
        weightLossPrescriptions = this.cart()!.prescriptions?.filter(prescription => prescription.items.some(item =>
          this.weightLossExceptionItems.some(ozempicItem =>
            item.productName.toLowerCase().includes(ozempicItem.toLowerCase())
          )
        ));
        nonWeightLossPrescriptions = this.cart()!.prescriptions?.filter(prescription => !prescription.items.some(item =>
          this.weightLossExceptionItems.some(ozempicItem =>
            item.productName.toLowerCase().includes(ozempicItem.toLowerCase())
          )
        ));
      } catch (error) {
        this.$error.showToast({ message: "There was an error creating the order" });
        console.error(error);
        loader.dismiss();
        throw error;
      }

      if (!this.doWeSplitOrder()) {
        try {
          const order: IOrder = {
            items: (this.otcItems() || []) as TOrderItem[],
            prescriptions: this.cart()?.prescriptions as TOrderPrescription[] || [],
            fulfillmentDetails: this.fulfillmentMethodToUse(),
            shipping: this.session()!.address!,
            card: this.cardToUse()!.toJSON(),
            paymentMethod: 'Card',
            associatedCard: this.cardToUse()!,
            allocatedShift: (this.selectedFulfillmentMethod() as TOrderFulfillmentMethodOnDemand)?.allocatedShift,
            promotionObject: this.selectedCoupon(),
            flagged: false,
          }

          const savedOrderObject = await this.$push.newOrder(order);
          this.$amplitude.track('CART_ORDER_CREATE_SUCCESS');
        } catch (error) {
          this.$amplitude.track('CART_ORDER_CREATE_FAIL');
          this.$error.showToast({ message: "There was an error creating the order" });
          console.error(error);
          loader.dismiss();
          throw error;
        }
      } else {
        try {
          const orderOne: IOrder = {
            items: (this.otcItems() || []) as TOrderItem[],
            prescriptions: this.nonWeightLossPrescriptions() as TOrderPrescription[] || [],
            fulfillmentDetails: this.selectedFulfillmentMethod()!,
            shipping: this.session()!.address!,
            card: this.cardToUse()!.toJSON(),
            paymentMethod: 'Card',
            associatedCard: this.cardToUse()!,
            allocatedShift: (this.selectedFulfillmentMethod() as TOrderFulfillmentMethodOnDemand)?.allocatedShift,
            promotionObject: this.selectedCoupon(),
            flagged: false,
          }
          const savedFirstOrderObject = await this.$push.newOrder(orderOne);

          const orderTwo: IOrder = {
            items: [],
            prescriptions: this.weightLossPrescriptions() as TOrderPrescription[] || [],
            fulfillmentDetails: this.isNicovapeOnly() ? this.postalMethod : this.postalTemperatureControlledMethod,
            shipping: this.session()!.address!,
            card: this.cardToUse()!.toJSON(),
            paymentMethod: 'Card',
            associatedCard: this.cardToUse()!,
            allocatedShift: undefined,
            promotionObject: undefined,
            flagged: false,
          }
          const savedSecondOrderObject = await this.$push.newOrder(orderTwo);
          this.$amplitude.track('CART_ORDER_CREATE_SUCCESS');
        } catch (error) {
          this.$amplitude.track('CART_WEIGHT_LOSS_ORDER_CREATE_FAIL');
          this.$error.showToast({ message: "There was an error creating the order" });
          console.error(error);
          loader.dismiss();
          throw error;
        }
      }
      this.$amplitude.track('CART_CHECKOUT_SUCCESS');
    } catch (error: any) {
      switch (error.code) {
        case 1001:
          this.$amplitude.track('CART_CHECKOUT_FAIL', { error: 'The account is blocked' });
          this.$error.showToast({ message: "Account is blocked, contact our help desk" });
          break;
        case 1002:
          this.$amplitude.track('CART_CHECKOUT_FAIL', { error: 'The card was declined' });
          this.$error.showToast({ message: "Card was declined, contact our helpdesk" });
          break;
        case 5002:
          this.$amplitude.track('CART_CHECKOUT_FAIL', { error: 'The address is not serviceable' });
          this.$error.showToast({ message: "Address is not serviceable, please contact our helpdesk" });
          break;
        case 1010:
          this.$amplitude.track('CART_CHECKOUT_FAIL', { error: 'The payment could not be processed, insufficient funds on card' });
          this.$error.showToast({ message: "Could not process payment, please contact our helpdesk" });
          break;
        case 9000:
          this.$amplitude.track('CART_CHECKOUT_FAIL', { error: 'Square returned an error' });
          this.$error.showToast({ message: "Error processing order, please contact our helpdesk" });
          break;
        case -1:
          this.$amplitude.track('CART_CHECKOUT_FAIL', { error: 'The card was declined' });
          this.$error.showToast({ message: error.message + ", contact our helpdesk" });
          break;
        default:
          this.$amplitude.track('CART_CHECKOUT_FAIL', { error: 'Unidentified network error' });
          this.$error.showToast({ message: "Unspecified error when trying to process order. Please contact our helpdesk." });
      }
    }

    this.closeCart();
    loader.dismiss();
  }
}
