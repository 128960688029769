import { Component, computed, input, output } from '@angular/core';
import { TitleComponent } from '../../primitives/title/title.component';
import { DeliveryMethodOptionComponent } from '../delivery-method-option/delivery-method-option.component';
import { IPharmacy, TOrderFulfillmentMethod, TOrderFulfillmentMethodOnDemand, TSessionAddress, TSessionPrefFulfillmentMethod } from '@chemist2u/types-client/C2U/Interfaces';

@Component({
    selector: 'app-delivery-method',
    templateUrl: './delivery-method.component.html',
    styleUrls: ['./delivery-method.component.scss'],
    standalone: true,
    imports: [DeliveryMethodOptionComponent, TitleComponent]
})
export class DeliveryMethodComponent {
    public pharmacyAddress = input<Partial<IPharmacy> | undefined>();
    public customerAddress = input<TSessionAddress | undefined>();
    public orderFulfillmentMethods = input.required<TOrderFulfillmentMethod[]>();
    public selectedDeliveryMethod = input<TOrderFulfillmentMethod>();
    public methodOverride = input<TOrderFulfillmentMethod>();
    public prefFulfillmentMethod = input<TSessionPrefFulfillmentMethod>();
    public selectMethodEvent = output<TOrderFulfillmentMethod>();
    public atlEvent = output<{atl: boolean, instructions: string | undefined}>();
    public updateShiftForOnDemandEventDevMethod = output<TOrderFulfillmentMethod>();
    public advanced = input<boolean>(false);
    public location = input<"cart" | "default">("default");

    public title = computed(() => {
        if (this.location() === "cart") {
            return "Delivery options";
        } else {
            return "Preferred delivery option";
        }
    });

    public methodSelected(method: TOrderFulfillmentMethod) {
        return this.selectMethodEvent.emit(method);
    }

    public updateShiftForOnDemand(method: TOrderFulfillmentMethod) {
        return this.updateShiftForOnDemandEventDevMethod.emit(method);
    }

    public clickAndCollectMethod = computed(() => {
        return this.orderFulfillmentMethods().find(method => method.selectedMethod.method === 'clickAndCollect');
    });

    public standardMethod = computed(() => {
        return this.orderFulfillmentMethods().find(method => method.selectedMethod.method === 'Standard');
    });

    public onDemandMethod = computed(() => {
        if ((this.selectedDeliveryMethod() as TOrderFulfillmentMethodOnDemand)?.allocatedShift) {
            return this.selectedDeliveryMethod();
        } else {
            return this.orderFulfillmentMethods().find(method => method.selectedMethod.method === 'OnDemand');
        }
    });

    public sameDayMethod = computed(() => {
        return this.orderFulfillmentMethods().find(method => method.selectedMethod.method === 'SameDay');
    });

    public postalMethod = computed(() => {
        return this.orderFulfillmentMethods().find(method => method.selectedMethod.method === 'Postal');
    });

    public onDemandSelected = computed(() => {
        if (this.selectedDeliveryMethod()) {
            if (this.selectedDeliveryMethod()!.selectedMethod.method === "OnDemand") {
                return true;
            } else {
                return false;
            }
        } else {
            if (this.prefFulfillmentMethod()) {
               if (this.prefFulfillmentMethod()! === "OnDemand") {
                   return true;
               } else {
                   return false;
               }
            } 
        }
        return false;
    });

    setAtl(atl: { atl: boolean, instructions: string | undefined }) {
        this.atlEvent.emit(atl);
    }

    public sameDaySelected = computed<boolean>(() => {
        return this.selectedDeliveryMethod()?.selectedMethod.method === "SameDay";
    });

    public postalSelected = computed<boolean>(() => {
        return this.selectedDeliveryMethod()?.selectedMethod.method === "Postal";
    });

    public standardSelected = computed<boolean>(() => {
        if (this.selectedDeliveryMethod()) {
            if (this.selectedDeliveryMethod()!.selectedMethod.method === "Standard") {
                return true;
            } else {
                return false;
            }
        } else {
            if(this.prefFulfillmentMethod()) {
               if(this.prefFulfillmentMethod()! === "Standard") {
                   return true;
               } else {
                   return false;
               }
            } 
        }
        return true;
    });

    public clickAndCollectSelected = computed(() => {
        if (this.selectedDeliveryMethod()) {
            if (this.selectedDeliveryMethod()!.selectedMethod.method === "clickAndCollect") {
                return true;
            } else {
                return false;
            }
        } else {
            if(this.prefFulfillmentMethod()) {
               if(this.prefFulfillmentMethod()! === "clickAndCollect") {
                   return true;
               } else {
                   return false;
               }
            } 
        }
        return false;
    });


}