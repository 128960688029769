import { Component, computed, inject, input, NgZone, OnDestroy, OnInit, signal } from '@angular/core';
import { TAllocatedShift, TFulfillmentMethodName, TOrderFulfillmentMethod, TOrderFulfillmentMethodOnDemand, TOrderFulfillmentMethodSameDay, TOrderFulfillmentMethodStandard } from '@chemist2u/types-client/C2U/Interfaces';
import { interval, map, Subscription } from 'rxjs';
import { GlobalTimingService } from 'src/app/services/global-timing.service';
import { StateService } from 'src/app/services/state.service';
import { TimeService } from 'src/app/services/time.service';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  standalone: true,
})
export class CountdownComponent implements OnInit, OnDestroy {
  private ngZone = inject(NgZone);
  private $state = inject(StateService);
  private $time = inject(TimeService);
  private $globalTiming = inject(GlobalTimingService);

  private methodSubscription?: Subscription;
  private intervalSubscription?: Subscription;
  private endTimeSubscription?: Subscription;
  
  public mode = input.required<"cart" | "order">();
  public methodId = input<TFulfillmentMethodName>();
  public orderId = input<string>();
  public roundedBtm = input<boolean>(false);

  private timeLeft = signal<string>('');
  private fulfillmentMethod = signal<TOrderFulfillmentMethod | undefined>(undefined);

  public isVisible = signal<boolean>(false);
  public message = computed<string | undefined>(() => {
    const doThis = this.mode() == "cart" ? "Place order" : "Pay for order";
    const timeLeft = this.timeLeft();
    const methodId = this.fulfillmentMethod()?.selectedMethod.method;
    if (methodId == 'clickAndCollect') {
      return `${doThis} within ${timeLeft} to pick up today before pharmacy closes`;
    } else if (methodId == 'OnDemand') {
      const method = this.fulfillmentMethod() as TOrderFulfillmentMethodOnDemand;
      const allocatedShift = method.allocatedShift!;
      return `${doThis} within ${timeLeft} to receive by ${this.$time.formatShiftLabel(allocatedShift)}`;
    } else if (methodId == 'Standard' || methodId == 'SameDay') {
      const expectedDeliveryDate = (this.fulfillmentMethod() as TOrderFulfillmentMethodStandard | TOrderFulfillmentMethodSameDay)!.expectedDeliveryDate!;
      const hourDiff = methodId == "SameDay" ? 3 : 4;
      const psuedoShift = {
        pickup: expectedDeliveryDate,
        dropoff: new Date(expectedDeliveryDate.getTime() + hourDiff * 60 * 60 * 1000),
        day: this.$time.formatDay(expectedDeliveryDate),
        date: expectedDeliveryDate,
      } as unknown as TAllocatedShift;
      return `${doThis} within ${timeLeft} to receive by ${this.$time.formatShiftLabel(psuedoShift)}`;
    }
    return undefined;
  });

  async ngOnInit() {
    if (this.mode() == "order" && !this.orderId()) {
      console.error('[CountdownComponent]', 'orderId input is required');
      return;
    } else if (this.mode() == "cart" && !this.methodId()) {
      console.error('[CountdownComponent]', 'methodId input is required');
      return;
    }

    this.setMethod();
    this.watchMethod();
  }

  ngOnDestroy(): void {
    this.stopCountdown();
    this.endTimeSubscription?.unsubscribe();
    this.methodSubscription?.unsubscribe();
  }

  private setMethod() {
    const method = this.mode() == "order"
      ? this.$state.bOrders.getValue().find(o => o.id == this.orderId())?.fulfillmentDetails
      : this.$state.bSelectedFulfillmentMethod.getValue();
    this.fulfillmentMethod.set(method);
    this.createTimerSub();
  }

  private watchMethod() {
    const observable = this.mode() == "order"
      ? this.$state.bOrders.pipe(map(orders => orders.find(o => o.id == this.orderId())?.fulfillmentDetails))
      : this.$state.bSelectedFulfillmentMethod;
    this.methodSubscription = observable.subscribe(method => {
      this.fulfillmentMethod.set(method);
      this.createTimerSub();
    });
  }

  private createTimerSub() {
    const endTime$ = this.mode() == "order"
      ? this.$globalTiming.getOrderEndTimeObservable(this.orderId()!)
      : this.$globalTiming.getFulfillmentMethodEndTimeObservable(this.methodId()!);
    
    if (!endTime$) return;

    this.endTimeSubscription?.unsubscribe();
    this.endTimeSubscription = endTime$.subscribe((endTime) => {
      if (endTime) {
        this.startCountdown(endTime);
      } else {
        this.timeLeft.set('');
        this.isVisible.set(false);
        this.stopCountdown();
      }
    });
  }

  private startCountdown(endTime: number): void {
    this.updateTimeLeft(endTime);

    // Set up interval to update every second
    this.stopCountdown();
    this.intervalSubscription = interval(1000).subscribe(() => {
      this.ngZone.run(() => {
        this.updateTimeLeft(endTime);
      });
    });
  }

  private stopCountdown(): void {
    this.intervalSubscription?.unsubscribe();
    this.intervalSubscription = undefined;
  }

  private updateTimeLeft(endTime: number): void {
    const remainingMs = endTime - Date.now();
    if (remainingMs > 0) {
      this.timeLeft.set(this.formatRemainingTime(remainingMs));
      this.isVisible.set(remainingMs < 12 * 60 * 60 * 1000); // Show if within 12 hours
    } else {
      this.timeLeft.set('');
      this.isVisible.set(false);
      this.stopCountdown();
    }
  }

  private formatRemainingTime(remainingMs: number): string {
    const totalSeconds = Math.floor(remainingMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  private padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
